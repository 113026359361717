import { FC, useEffect, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';

import logger from '@common/log';
import { stripAccolades } from '@common/string';
import { useFormsPublicV2PostFormV2 } from '@dc/hooks';
import useDC from '@dc/useDC';
import { GenericFormRendering } from '@sitecore/types/manual/GenericForm';

import CommonGenericForm from './CommonGenericForm';
import { ErrorType, FormValues, prepareRequest } from './util';

const GenericForm: FC<GenericFormRendering> = ({ fields, placeholders }) => {
  const { label, businessUnit } = useDC();
  const { error, isError, isSuccess, send } = useFormsPublicV2PostFormV2();

  const {
    senderIdOption: { value: senderId },
  } = fields;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorType, setErrorType] = useState<ErrorType | undefined>();

  useEffect(() => {
    if (!isError) return;

    logger.error('Wxd7AY', 'Failed to submit form', { error });
    setErrorType(undefined);
  }, [isError, error]);

  const submitForm: SubmitHandler<FormValues> = async values => {
    setIsSubmitting(true);

    try {
      const requestBody = prepareRequest(senderId, values);

      await send({
        businessUnit,
        formId: stripAccolades(senderId),
        label,
        requestBody,
        recaptchaToken: values.recaptchaToken,
      });
    } catch (error) {
      logger.error('Wxd7AY', 'Failed to submit form', { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonGenericForm
      fields={fields}
      placeholder={placeholders['jss-form']}
      placeholderName="jss-form"
      submitForm={submitForm}
      isSubmitting={isSubmitting}
      errorType={errorType}
      isSuccess={isSuccess}
    />
  );
};

export default GenericForm;
