import { FC, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { getCombinedLinkFieldValueHref } from '@common/link';
import { toURL } from '@common/path';
import { Recaptcha, RecaptchaRef } from '@components/Recaptcha/Recaptcha';
import { RecaptchaForm } from '@components/RecaptchaForm/RecaptchaForm';
import RichText from '@components/RichText/RichText';
import { useRouter } from '@dxp-next';
import { Placeholder } from '@sitecore/common';
import { CommonGenericFormRendering } from '@sitecore/types/manual/CommonGenericFormRendering';
import { Button, Heading, NotificationBox, Stack } from '@sparky';

import { FormValues, genericFormSchema } from './util';

const CommonGenericForm: FC<CommonGenericFormRendering> = ({
  fields,
  placeholder,
  placeholderName,
  submitForm,
  isSubmitting,
  errorType,
  isSuccess,
}) => {
  const recaptchaRef = useRef<RecaptchaRef>(null);
  const { pushHref } = useRouter();
  const { description, title, formSubmitRedirectLink } = fields;

  const resolver = yupResolver(genericFormSchema(placeholder));
  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver,
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isSuccess) return;

    const redirectLink = getCombinedLinkFieldValueHref(formSubmitRedirectLink.value);

    pushHref(toURL(redirectLink));
  }, [fields, isSuccess, pushHref]);

  return (
    <FormProvider {...methods}>
      <RecaptchaForm onSubmit={submitForm} recaptchaRef={recaptchaRef}>
        <Stack gap="6">
          {title?.value && (
            <Heading as="h2" size="M">
              {title.value}
            </Heading>
          )}
          {description?.value && <RichText html={description.value}></RichText>}
          <Placeholder name={placeholderName} />
          <Stack.Item>
            <Recaptcha hasError={!!errors.recaptchaToken} ref={recaptchaRef} />
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" isLoading={isSubmitting}>
              {formSubmitRedirectLink?.value?.text}
            </Button>
          </Stack.Item>
        </Stack>
      </RecaptchaForm>
      {errorType ? (
        <NotificationBox
          isAlert
          text={<RichText html={`${errorType}ErrorNotification`} />}
          title={`${errorType}ErrorNotification`}
        />
      ) : null}
    </FormProvider>
  );
};

export default CommonGenericForm;
